import React, { useMemo } from "react"
import { navigate } from "gatsby"
import { useCore } from "../../../hooks/useCore"
import { useSettings } from "../../../hooks/useSettings"

export const withRegister = Component =>
  React.memo(({ name = "Register", page }) => {
    const {
      helpers: { sanityContent },
    } = useCore()

    const title = page?.title
    const content = sanityContent(page?.content)

    const { membership } = useSettings()
    if (!membership?.enabled && typeof window !== "undefined") navigate("/wait-list", { replace: true })

    Component.displayName = name
    return useMemo(() => <Component title={title} content={content} />, [])
  })
