import React from "react"

import { withRegisterForm } from "./withRegisterForm"
import { Content, SignIn, SignInLink, Button as SmallButton } from "./RegisterForm.styles"
import { Form, Description, Field, Button, ButtonWrapper, ErrorMessage } from "../../Form/Form.styled"

export const RegisterForm = withRegisterForm(
  ({ data, handleChange, handleSubmit, loading, errors, content, homepage }): JSX.Element => (
    <Form onSubmit={handleSubmit} homepage={homepage} data-rewardful>
      {errors?.length > 0 && (
        <Description>
          <ul>
            {errors?.map((error, index) => (
              <li key={index}>
                <ErrorMessage homepage={homepage}>{error?.message}</ErrorMessage>
              </li>
            ))}
          </ul>
        </Description>
      )}
      <Field
        name={"firstName"}
        type={"text"}
        placeholder={"Name"}
        value={data?.firstName}
        onChange={handleChange}
        required={data?.firstName?.length > 0}
        autoFocus
        error={errors?.filter(error => error?.field?.includes("firstName"))?.length}
      />
      <Field
        name={"email"}
        type={"email"}
        placeholder={"Email address"}
        value={data?.email}
        onChange={handleChange}
        required
        error={errors?.filter(error => error?.field?.includes("email"))?.length}
      />
      <Field
        name={"password"}
        type={"password"}
        placeholder={"Password (8 characters or more)"}
        value={data?.password}
        onChange={handleChange}
        required
        error={errors?.filter(error => error?.field?.includes("password"))?.length}
      />
      {content ? <Content>{content}</Content> : null}
      <ButtonWrapper center={true}>
        {homepage ? (
          <SmallButton
            type={`submit`}
            size={`large`}
            colour={`dark`}
            theme={`primary`}
            center={true}
            loading={loading}
            disabled={data?.email?.length < 6 || data?.firstName < 4 || data?.password?.length < 8}
          >
            {loading ? "Saving..." : "Join Now"}
          </SmallButton>
        ) : (
          <Button
            type={`submit`}
            size={`large`}
            colour={`dark`}
            theme={`primary`}
            center={true}
            loading={loading}
            disabled={data?.email?.length < 6 || data?.firstName < 4 || data?.password?.length < 8}
          >
            {loading ? "Saving..." : "Join Now"}
          </Button>
        )}
      </ButtonWrapper>
      <SignIn>
        {`Have an account?`} <SignInLink theme={`primary`} to={`/account/login`}>{`Log In`}</SignInLink>
      </SignIn>
    </Form>
  )
)

export interface Props {
  title: string
  button: string
  fields?: Array<{
    name: string
    placeholder: string
    type?: string
  }>
  children: any
  homepage?: boolean
}
