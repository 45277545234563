import React, { useMemo } from "react"

import { useCustomerRegister } from "../../../hooks/useCustomer"

export const withRegisterForm = Component =>
  React.memo(({ name = "RegisterForm", content, homepage }) => {
    const { createCustomer, data, setData, loading, errors } = useCustomerRegister()

    const handleSubmit = async event => {
      event.preventDefault()
      const filteredData: any = Object.entries(data).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {})
      await createCustomer({
        ...filteredData,
        email: filteredData?.email?.toLowerCase(),
        firstName: filteredData?.firstName?.split(/\s(.+)/)?.[0] || filteredData?.firstName,
        lastName: filteredData?.firstName?.split(/\s(.+)/)?.[1] || "",
      })
    }

    const handleChange = ({ target: { type, name, value, checked } }) => {
      setData(prevState => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }))
    }

    const errorMessages = errors?.map(error => ({
      ...error,
      message:
        error?.message
          ?.replace("Email has already been taken", "Email has already been registered. Login?")
          ?.replace("Creating Customer Limit exceeded. Please try again later.", "You've attempted too many times, try again later.") || error,
    }))

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          content={content}
          data={data}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          loading={loading}
          errors={errorMessages}
          homepage={homepage}
        />
      ),
      [data, loading, errors]
    )
  })
