import React from "react"

import { withRegister } from "./withRegister"
import { RegisterForm } from "./RegisterForm"
import { Container, Frame, Title } from "../../Form/Form.styled"

export const Register = withRegister(
  ({ title, content }): JSX.Element => (
    <Container width={`md`}>
      <Frame>
        <Title description={content}>{title}</Title>
        <RegisterForm content={content} />
      </Frame>
    </Container>
  )
)
